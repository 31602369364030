import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { Field, Form } from "../template";
import GroupMembers from "../group/GroupMembers";
import Brief from "./Brief";
import { selectValidation } from "../utils/validations";
import { DatePicker, Select, Text, Toggle } from "../inputs";
import { t } from "i18next";
import View from "./View";

const InductionForm = ({ editable }) => {
	const { id } = useParams();
	const props = {
		editable: ({ status }) => editable && status === "pending",
		model: "induction-plan",
		id,
		init: {
			type: "",
			template: "",
			text: "",
			// location: "",
		},
		validationSchema: Yup.object().shape({
			type: selectValidation(true),
			template: selectValidation(true),
			// location: selectValidation(true),
		}),
		beforeSubmit: (values) => {
			if (
				!values.all &&
				(!values.users || values.users.length < 1) &&
				(!values.groups || values.groups.length < 1) &&
				(!values.units || values.units.length < 1)
			)
				throw new Error("induction-plan.select_user");

			return values;
		},
		view: (values) => <View {...values} key={id} id={id} />,
	};

	return (
		<Form {...props}>
			{({ disabled, values }) => {
				return (
					<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
						<div>
							<Field
								name="schedule"
								label="induction-plan.schedule"
								disabled={disabled}
								component={DatePicker}
								time={true}
								helper={() => (
									<div className="text-xs">
										{t("induction-plan.schedule-helper")}
									</div>
								)}
							/>
							<Field
								name="type"
								label="induction-template.type"
								disabled={disabled}
								component={Select}
								axio="/const/static/induction_type"
							/>
							<Field
								name="template"
								label="induction-plan.template"
								disabled={disabled}
								component={Select}
								axio="/const/select/induction-template"
							/>
							{typeof values.template === "string" && (
								<Brief template={values.template} />
							)}
						</div>
						<div>
							{/* <Field
								name="location"
								label="induction-plan.location"
								disabled={disabled}
								component={Select}
								axio="/const/select/location"
							/> */}
							<Field
								name="all"
								label="induction-plan.all"
								disabled={disabled}
								component={Toggle}
								inline
								reverse
							/>
							{!values.all && (
								<>
									<Field
										name="users"
										label="induction-plan.users"
										disabled={disabled}
										component={Select}
										axio="/const/select/user"
										isMulti={true}
										// eslint-disable-next-line no-template-curly-in-string
										labelTemplate="${firstname} ${lastname} (${position})"
									/>
									<Field
										name="groups"
										label="induction-plan.groups"
										disabled={disabled}
										component={Select}
										axio="/const/select/group"
										isMulti={true}
										closeOnSelect={true}
									/>
									<Field
										name="units"
										label="induction-plan.units"
										disabled={disabled}
										component={Select}
										axio="/const/select/unit"
										isMulti={true}
										closeOnSelect={true}
									/>
									<GroupMembers groups={values.groups} />
								</>
							)}
							<Field
								name="text"
								label="induction-plan.text"
								disabled={disabled}
								component={Text}
								type="textarea"
								rows="5"
							/>
						</div>
					</div>
				);
			}}
		</Form>
	);
};

export default InductionForm;
