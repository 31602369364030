import TabLayout from "./Tabs";
import {
	RiAlarmWarningFill,
	RiAlarmWarningLine,
	RiAlertLine,
	RiArticleLine,
	RiBroadcastLine,
	RiCalendar2Fill,
	RiChatCheckLine,
	RiCheckboxMultipleLine,
	RiEditBoxLine,
	RiFindReplaceLine,
	RiFolder2Line,
	RiHandHeartLine,
	RiIndeterminateCircleLine,
	RiInformationLine,
	RiLineChartLine,
	RiListCheck,
	RiListOrdered,
	RiMapPin2Line,
	RiMedal2Line,
	RiMeteorLine,
	RiNewspaperLine,
	RiNodeTree,
	RiOrganizationChart,
	RiPriceTag3Line,
	RiQuestionAnswerLine,
	RiScalesLine,
	RiSettings2Line,
	RiShareLine,
	RiSurveyLine,
	RiTaskLine,
	RiTeamLine,
	RiTestTubeLine,
	RiUserLine,
	RiVideoLine,
} from "react-icons/ri";

export const hazardPanel = (element) => {
	return (
		<TabLayout
			items={[
				{
					path: "pnl-hazard/hazard",
					label: "list",
					icon: <RiAlertLine />,
				},
				{
					path: "pnl-hazard/category",
					label: "category",
					icon: <RiNodeTree />,
				},
				{
					path: "pnl-hazard/report",
					label: "report",
					icon: <RiLineChartLine />,
				},
				{
					path: "pnl-hazard/task",
					label: "task",
					icon: <RiTaskLine />,
				},
			]}
		>
			{element}
		</TabLayout>
	);
};

export const alertPanel = (element) => {
	return (
		<TabLayout
			items={[
				{
					path: "pnl-alert/alert",
					label: "list",
					icon: <RiBroadcastLine />,
				},
				// {
				// 	path: "pnl-alert/category",
				// 	label: "category",
				// 	icon: <RiNodeTree />,
				// },
				{
					path: "pnl-alert/report",
					label: "report",
					icon: <RiLineChartLine />,
				},
			]}
		>
			{element}
		</TabLayout>
	);
};

export const violationPanel = (element) => {
	return (
		<TabLayout
			items={[
				{
					path: "pnl-violation/violation",
					label: "list",
					icon: <RiIndeterminateCircleLine />,
				},
				{
					path: "pnl-violation/category",
					label: "category",
					icon: <RiNodeTree />,
				},
				{
					path: "pnl-violation/report",
					label: "report",
					icon: <RiLineChartLine />,
				},
			]}
		>
			{element}
		</TabLayout>
	);
};

export const trainingPanel = (element) => {
	return (
		<TabLayout
			items={[
				{
					path: "pnl-training/training",
					label: "allocation",
					icon: <RiCalendar2Fill />,
				},
				{
					path: "pnl-training/training-content",
					label: "contents",
					icon: <RiVideoLine />,
				},
				{
					path: "pnl-training/report",
					label: "report",
					icon: <RiLineChartLine />,
				},
			]}
		>
			{element}
		</TabLayout>
	);
};

export const inductionPanel = (element) => {
	return (
		<TabLayout
			items={[
				{
					path: "pnl-induction/induction",
					label: "allocation",
					icon: <RiCalendar2Fill />,
				},
				{
					path: "pnl-induction/induction-template",
					label: "contents",
					icon: <RiInformationLine />,
				},
				{
					path: "pnl-induction/report",
					label: "report",
					icon: <RiLineChartLine />,
				},
			]}
		>
			{element}
		</TabLayout>
	);
};

export const checklistPanel = (element) => {
	return (
		<TabLayout
			items={[
				{
					path: "pnl-checklist/checklist",
					label: "list",
					icon: <RiListCheck />,
				},
				{
					path: "pnl-checklist/checklist-template",
					label: "template",
					icon: <RiChatCheckLine />,
				},
				{
					path: "pnl-checklist/category",
					label: "category",
					icon: <RiNodeTree />,
				},
				{
					path: "pnl-checklist/report",
					label: "report",
					icon: <RiLineChartLine />,
				},
			]}
		>
			{element}
		</TabLayout>
	);
};

export const surveyPanel = (element) => {
	return (
		<TabLayout
			items={[
				{
					path: "pnl-survey/survey",
					label: "list",
					icon: <RiListCheck />,
				},
				{
					path: "pnl-survey/survey-template",
					label: "template",
					icon: <RiChatCheckLine />,
				},
			]}
		>
			{element}
		</TabLayout>
	);
};

export const stepPanel = (element) => {
	return (
		<TabLayout
			items={[
				{
					path: "pnl-step/step",
					label: "list",
					icon: <RiListOrdered />,
				},
				{
					path: "pnl-step/step-template",
					label: "template",
					icon: <RiCheckboxMultipleLine />,
				},
				{
					path: "pnl-step/report",
					label: "report",
					icon: <RiLineChartLine />,
				},
			]}
		>
			{element}
		</TabLayout>
	);
};

export const permissionPanel = (element) => {
	return (
		<TabLayout
			items={[
				{
					path: "pnl-permission/permission",
					label: "list",
					icon: <RiQuestionAnswerLine />,
				},
				{
					path: "pnl-permission/permission-template",
					label: "template",
					icon: <RiEditBoxLine />,
				},
				{
					path: "pnl-permission/report",
					label: "report",
					icon: <RiLineChartLine />,
				},
			]}
		>
			{element}
		</TabLayout>
	);
};

export const ppePanel = (element) => {
	return (
		<TabLayout
			items={[
				{
					path: "pnl-ppe/ppe",
					label: "allocation",
					icon: <RiHandHeartLine />,
				},
				{
					path: "pnl-ppe/ppe-item",
					label: "ppe-item",
					icon: <RiPriceTag3Line />,
				},
				{
					path: "pnl-ppe/report",
					label: "report",
					icon: <RiLineChartLine />,
				},
			]}
		>
			{element}
		</TabLayout>
	);
};

export const inspectionPanel = (element) => {
	return (
		<TabLayout
			items={[
				{
					path: "pnl-inspection/inspection",
					label: "list",
					icon: <RiFindReplaceLine />,
				},
				{
					path: "pnl-inspection/category",
					label: "category",
					icon: <RiNodeTree />,
				},
				{
					path: "pnl-inspection/report",
					label: "report",
					icon: <RiLineChartLine />,
				},
			]}
		>
			{element}
		</TabLayout>
	);
};

export const incidentPanel = (element) => {
	return (
		<TabLayout
			items={[
				{
					path: "pnl-incident/incident",
					label: "list",
					icon: <RiMeteorLine />,
				},
				{
					path: "pnl-incident/category",
					label: "incident-category",
					icon: <RiNodeTree />,
				},
				{
					path: "pnl-incident/cause",
					label: "cause",
					icon: <RiShareLine />,
				},
				{
					path: "pnl-incident/report",
					label: "report",
					icon: <RiLineChartLine />,
				},
			]}
		>
			{element}
		</TabLayout>
	);
};

export const userPanel = (element) => {
	return (
		<TabLayout
			items={[
				{
					path: "pnl-user/user",
					label: "list",
					icon: <RiUserLine />,
				},
				{
					path: "pnl-user/group",
					label: "group",
					icon: <RiTeamLine />,
				},
				{
					path: "pnl-user/point",
					label: "point",
					icon: <RiMedal2Line />,
				},
				{
					path: "pnl-user/point-settings",
					label: "point-settings",
					icon: <RiSettings2Line />,
				},
			]}
		>
			{element}
		</TabLayout>
	);
};

export const documentPanel = (element) => {
	return (
		<TabLayout
			items={[
				{
					path: "pnl-document/procedure",
					label: "procedure",
					icon: <RiNewspaperLine />,
				},
				{
					// path: "pnl-document/law",
					label: "law",
					icon: <RiScalesLine />,
				},
				{
					// path: "pnl-document/sds",
					label: "sds",
					icon: <RiTestTubeLine />,
				},
				{
					path: "pnl-document/document/undefined",
					label: "document",
					icon: <RiFolder2Line />,
				},
				{
					// path: "pnl-document/report",
					label: "report",
					icon: <RiLineChartLine />,
				},
			]}
		>
			{element}
		</TabLayout>
	);
};

export const orgPanel = (element) => {
	return (
		<TabLayout
			items={[
				{
					path: "pnl-org/news",
					label: "news",
					icon: <RiArticleLine />,
				},
				{
					path: "pnl-org/emergency",
					label: "emergency",
					icon: <RiAlarmWarningLine />,
				},
				{
					path: "pnl-org/alarm",
					label: "alarm",
					icon: <RiAlarmWarningFill />,
				},
				{
					path: "pnl-org/location",
					label: "location",
					icon: <RiMapPin2Line />,
				},
				{
					path: "pnl-org/unit",
					label: "unit",
					icon: <RiOrganizationChart />,
				},
				{
					path: "pnl-org/settings",
					label: "settings",
					icon: <RiSettings2Line />,
				},
			]}
		>
			{element}
		</TabLayout>
	);
};
